.media-footer-anchor {
  height: 300px;
  width: 100%;
  max-width: 100vw;

  .media-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 8px);
    height: 120px;
    background-color: var(--bg-color);
    z-index: 100;

    .FormLine {
      margin: 0 3vw;
      width: 94vw;
    }
    .form-actions {
      margin: 0 3vw;
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      background-color: var(--bg-color);

      .__previous {
        background-color: var(--bg-color);
        border: 2px solid #979797;
      }
    }
  }
}

