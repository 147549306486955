@import "src/sass/variables";
@import "src/sass/utils";

.LoginForm {
  width: 100%;

  .TopForm {
    margin-bottom: 0;
  }
}

.ForgotPasswordContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.TransparentButton {
  width: 100%;
  height: 62px;
  background-color: transparent;
  border: 1px solid #eeeeee;
  border-radius: 70px;
  color: var(--primary-text-color);
  font-size: 24px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 10px;
  cursor: pointer;

  @include phone {
    height: 50px;
    font-size: 14px;
  }
}
