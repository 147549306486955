@import "src/sass/utils";

body.update-consent-modal-open {
  overflow: hidden !important;  
}

.user-consent-update-modal {
  &__container {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
  }

  &__border {
    max-width: 730px;
    width: inherit;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border: 1px solid rgba(63, 141, 234, 0.3);
    border-radius: 5px;
    padding: 1px;

    @include tabletDown {
      max-width: 700px;
    }
  }

  &__content {
    padding: 45px 25px;
    background: #fff;
    border: 1px solid #3f8dea;
    border-radius: 3px;
  }
    
  &__logo {
    text-align: center;

    img {
        width: 200px;
        margin-bottom: 30px;
    }
  }

  &__header {
    text-align: center;
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__text {
    color: #000;
    font-size: 15px;
    line-height: 160%;
  }

  &__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    font-size: 16px;

    @include tabletDown {
      flex-direction: column;
      align-items: center;
    }
  }

  &__link {
    @include tabletDown {
      width: 100%;
    }
  }

  &__button {
    cursor: pointer;
    padding: 8px 16px;
    font-weight: 700;
    width: 200px;
    border-width: 1px;

    @include tabletDown {
      width: 100%;
      margin-bottom: 10px;
    }
    
    &--primary {
      background-color: #3f8dea;
      border-color: rgba(63, 141, 234, 0.3);
    }
    
    &--secondary {
      color: #444444;
      background-color: #eeeeee;
      border-color: rgba(34, 34, 34, 0.2);
    }
  }  
}