@import "src/sass/utils";
@import "src/sass/variables";

.PaymentStatus {
  &__content {
    max-width: 721px;
    margin: 0 auto;
  }

  section {
    padding-left: var(--app-padding-left);
    padding-right: var(--app-padding-right);
    text-align: center;
  }
}
