@import "src/sass/utils";

.GlobalSearch {
  right: 0;
  width: 0;
  text-align: right;
  transition: all 0.5s ease-in-out;
  z-index: 2;
  cursor: pointer;

  @include desktopDown {
    transition: unset;
  }

  .Search__icon {
    width: 26px;
    height: 26px;

    @include phone {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 100%;
      height: 100%;
      filter: $icon-shadow;
    }

    svg:hover {
      color: var(--header-link-hover-color);
    }
  }

  .Input {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 9px;
    justify-content: flex-end;
    transition: all 0.5s, color 0s;
    background-color: transparent;
    border-bottom: solid 1px var(--primary-text-color-d-30);
    height: unset;

    @include desktopDown {
      transition: unset;
    }

    &:hover {
      color: var(--header-link-hover-color);
    }

    &__suffix {
      @include phone {
        margin-right: 20px;
      }
    }

    input {
      width: 0;
      transition: all 0.5s, color 0s;

      @include desktopDown {
        transition: unset;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__clear,
    &__clear svg {
      width: 24px;
      height: 24px;
    }
  }

  &--value,
  &--focused {
    width: 100%;
    padding-left: 80px;

    @include desktopDown {
      width: calc(100vw - 80px - 40px);
      position: absolute;
      left: 0;
      transform: translateY(52px);
    }

    @include phone {
      width: calc(100vw - 16px - 24px);
      padding-left: 16px;
    }

    .AppHeader {
      background: red;
    }

    .Input {
      width: 100%;

      &__suffix {
        @include phone {
          margin-right: 0;
        }
      }

      input {
        width: 100%;
      }
    }
  }
}
