.MediaIcon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.2s;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.93)
  );
  color: var(--primary-color);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 64px;
    transform: translate(-50%, -50%);
  }
}
