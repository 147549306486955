.Result {
  &__icon {
    font-size: 10rem;
    text-align: center;

    &--success {
      color: green;
    }

    &--error {
      color: red;
    }
  }
}
