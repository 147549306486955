@import "src/sass/utils";
@import "src/sass/variables";

@keyframes bounceHorizontal {
  0% {
    transform: matrix(2, 0, 0, 2, 20, 0);
  }
  25% {
    transform: matrix(1.5, 0.3, -0.3, 1.5, 20, 0);
  }
  50% {
    transform: matrix(2, 0, 0, 2, 20, 0);
  }
  75% {
    transform: matrix(1.5, -0.3, 0.3, 1.5, 20, 0);
  }
  100% {
    transform: matrix(2, 0, 0, 2, 20, 0);
  }
}

@keyframes bounceVertical {
  0% {
    transform: matrix(1.5, 0, 0, 1.5, 0, 8);
  }
  25% {
    transform: matrix(1, 0.3, -0.3, 1, 0, 8);
  }
  50% {
    transform: matrix(1.5, 0, 0, 1.5, 0, 8);
  }
  75% {
    transform: matrix(1, -0.3, 0.3, 1, 0, 8);
  }
  100% {
    transform: matrix(1.5, 0, 0, 1.5, 0, 8);
  }
}

.reaction {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  width: 40px;
  height: 40px;
  text-align: center;

  &__icon {
    user-select: none;
    height: 40px;
    transform: scale(0.8);
    filter: brightness(1);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      filter: brightness(1.1);
      transform: matrix(2, 0, 0, 2, 20, 0);
      animation: bounceHorizontal 1.5s linear infinite;
      animation-delay: 0.3s;
      @include tabletDown {
        transform: matrix(1.5, 0, 0, 1.5, 0, 8);
        animation: bounceVertical 1.5s linear infinite;
        animation-delay: 0.3s;
      }
    }
    &:active {
      transition: transform 0.1s ease-in-out;
      filter: saturate(4);
      animation: none;
      transform: matrix(2, 0, 0, 2, 20, 6);
      @include tabletDown {
        transform: matrix(1.5, 0, 0, 1.5, 0, 14);
      }
    }
  }

  &__percentage {
    position: relative;
    top: -4px;
  }
}
