.media-images-form {
  display: flex;
  flex-direction: column;
  
  .images-container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    gap: 64px;
    margin: 0 auto;

    .image-frame {
      width: 30vw;
    }

    .image-cover {
      width: 25vw;
    }

    .image-highlights {
      width: 35vw;
    }
  }
}
