@import "src/sass/utils";
@import "src/sass/epg";

.epg-desktop-container {
  height: fit-content;
  position: relative;
  overflow: hidden;
  width: 100%;
  overflow: hidden;
  clip-path: inset(0);

  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background: $background-secondary-color;
  }

  &-empty {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 900;
    background: $background-contrast-color;
    width: $epg-desktop-sidebar-width;

    @include largeDown() {
      width: $epg-desktop-sidebar-width-tablet;
    }

    &.fixed-epg-elements {
      position: fixed;
      top: 0;
      z-index: 40;
    }

    .epg-desktop-buttons {
      z-index: 15;
    }
  }

  &-loader {
    position: absolute;
    display: flex;
    width: inherit;
    height: inherit;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 900;
    background: $loader-overlay-background-color;
  }
}
