@import "src/sass/variables";
@import "src/sass/utils";

.SettingsTerms {
  &__Option {
    padding: 2em;
    label {
      display: flex;
      align-items: center;
    }
    span {
      @include text-style();
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
    }
    &__Description {
      padding-left: 1em;
    }
  }
}

.RequiredConsent {
  padding-right: 10px;
  color: $error-color;
  font-weight: bold !important;
}

.link {
  opacity: 0.8;
}

.underline {
  text-decoration: underline;
}
