.AssetInfoOverlay {
  position: relative;
  margin: 0;
  padding: 0;
  background: #0e0e0e;

  &__Background {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
  }

  &__Header {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 60px;

    &--backIcon {
      display: flex;
      font-size: 8px;
      height: 22px;
      width: 22px;
      justify-content: center;
      align-items: center;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 50%;
      svg {
        path {
          fill: #fff;
        }
      }
      &:hover {
        cursor: pointer;
        color: #c4c4c4;
        border-color: #c4c4c4;
        svg {
          path {
            fill: #c4c4c4;
          }
        }
      }
    }

    &--logo {
      display: block;
      height: 1.8rem;
      margin-left: 1rem;
    }
  }

  &__Container {
    position: absolute;
    left: 10%;
    top: 30%;
  }

  &__Content {
    width: 40%;
  }

  &__Title {
    opacity: 0.87;
    font-size: 4rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.01;
    letter-spacing: normal;
    padding: 0;
    margin: 0 0 8px;
    user-select: none;
  }

  &__Description {
    opacity: 0.8;
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: 0.68px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 96px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    user-select: none;
  }
}
