.summary-section {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  width: 100%;

  .details {
    width: 50%;
    .info-section {
      margin-top: 40px;
    }
  }
  .info-secondary {
    font-size: 14px;
    margin-block-end: 8px;
    margin-block-start: 8px;
    overflow-x: hidden;
  }
  
  .info-primary {
    margin-block-start: 8px;
    margin-block-end: 8px;
    font-weight: bold;
    font-size: 20px;
  }
  
  .info-horizontal {
    display: flex;
    flex-direction: row;
  
    .info-primary {
      padding-right: 12px;
    }
  }
  
  .category {
    border-right: 2px solid var(--primary-text-color);
    margin-right: 12px;
  }
  .preview {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    height: 100%;

    .image {
      max-width: 850px;
      width: 100%;
      height: auto;
      border-radius: 20px;
    }
  }
  .start-date-time-info {
    display: flex;
    gap: 30px;
    width: 100%;
  }
}


