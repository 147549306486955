.PaymentDetailsModal {
  &__field {
    margin-bottom: 20px;
  }

  &__label {
    font-weight: 700;
    padding-right: 10;
  }
}
