.pills-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: scroll;
  margin: 16px 0 32px;
  gap: 16px 0;
}

.dialog-add {
  margin-left: auto;
  padding: 16px;
  border-radius: 27px;
  border-color: 1px solid var(--primary-color);
  background: none;
  color: var(--primary-text-color);
}

@import "../Pill/Pill.scss";