@import "src/sass/utils";
@import "src/sass/epg";

.epg-desktop-program {
  position: absolute;
  overflow: hidden;

  &-content {
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;
    overflow: hidden;
    font-weight: 400;
    letter-spacing: 0.2px;
    cursor: pointer;

    background: $background-secondary-color;
    color: $text-color;

    @include transition($epg-transition);

    &:hover {
      background: $active-color !important;
      color: $text-hover-color;

      svg {
        display: block !important;

        @include largeDown() {
          display: none !important;
        }
      }
    }

    &-flex {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      &-stack {
        overflow: hidden;
      }
    }

    &-second-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;

      @include largeDown() {
        font-size: 24px;
      }
    }

    &-title, &-time {
      font-weight: 400;
      display: block;
      text-align: left;

      @include ellipsis();
    }

    &-title {
      @include text-style('half', 'bolder');
      padding-bottom: 8px;
    }

    &-time {
      @include text-style();
    }

    &-play-icon {
      margin-bottom: 5px;
      svg {
        width: 30px;
        height: 30px;
        display: none;
      }
    }
  }

  &:hover {
    z-index: 200;
    transition: all .1s ease-in-out;
    position: absolute;
    transform: scale(1.05) translateX(2.4%);
    min-width: fit-content;

    &-content {
      width: fit-content;

      &-flex {
        width: fit-content;

        &-stack {
          width: fit-content;

          &-title {
            width: fit-content;
          }
        }
      }

    }
  }
}
