@import "src/sass/utils";

.ApplicationMenuItem .circular {
  display: inline-block;
  position: relative;
  width: 26px;
  height: 26px;
  overflow: hidden;
  text-align: center;
  margin-left: 26px;

  svg {
    width: 26px;
    height: 26px;
    filter: $icon-shadow;
  }
}

.dd-container {
  position: relative;
}
.dd-wrapper {
  background-color: transparent;
  opacity: 1;
  user-select: none;

  .dd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.dd-select {
  position: absolute;
  right: 0;
  display: block;
  padding: 23px 22px;
  margin-top: 9px;
  border-radius: 8px;
  background-color: var(--bg-color);
  list-style-type: none;
  width: max-content;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

  .Menu-vertical .Menu-item {
    display: flex;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    cursor: pointer;

    &:first-of-type {
      margin-top: 0;
    }

    &:hover {
      color: var(--header-link-hover-color);
    }
  }

  .Menu:not(.Menu-horizontal) .Menu-item-selected {
    background-color: transparent;
  }

  .Menu {
    &:not(&-horizontal) &-item-selected {
      background-color: transparent;
    }
    &-item:active,
    &-submenu-title:active {
      background: transparent;
    }
  }
}
