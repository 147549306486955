@import "src/sass/utils";
@import "src/sass/variables";

$desktop-aspect-ratio: 6.07/1;
$tablet-aspect-ratio: 5.59/1;
$phone-aspect-ratio: 2.24/1;

.ListComponentItemLandscape {
  position: relative;
  transition: transform 0.2s;
  aspect-ratio: $desktop-aspect-ratio;
  border-radius: 5px;
  background-color: var(--cell-background-color);
  cursor: pointer;

  @include desktopDown {
    aspect-ratio: $tablet-aspect-ratio;
  }

  @include phone {
    aspect-ratio: $phone-aspect-ratio;
  }

  &-container {
    aspect-ratio: $desktop-aspect-ratio;
    border-radius: 5px;

    &--skeleton {
      background: var(--cell-background-color);
      margin: 0 12.5px;
      @include phone {
        margin-left: 15px;
      }
    }

    @include desktopDown {
      aspect-ratio: $tablet-aspect-ratio;
    }

    @include phone {
      aspect-ratio: $phone-aspect-ratio;
    }
  }
  &-image-container {
    aspect-ratio: $desktop-aspect-ratio;
    border-radius: 5px;
    height: 100%;

    @include desktopDown {
      aspect-ratio: $tablet-aspect-ratio;
    }

    @include tabletDown {
      aspect-ratio: $phone-aspect-ratio;
    }
  }
  &-image {
    width: 100%;
    height: 100%;
    img {
      border-radius: 5px;
      object-fit: cover;
    }
  }

  &-skeleton {
    border-radius: 5px;
    position: absolute;
    width: 100%;
    bottom: 26px;
    left: 20px;

    @include phone {
      margin-left: 15px;
    }
  }
}
