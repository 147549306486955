@import "src/sass/utils";
@import "src/sass/variables";

.PaymentCancel {
  padding-bottom: 5rem;

  &__title {
    opacity: 0.87;
    font-size: 2.25rem;
    font-weight: 700;
    letter-spacing: 1.35px;
    text-align: center;
    color: var(--primary-text-color);
    margin-bottom: 0.625rem;
  }

  &__subtitle {
    opacity: 0.87;
    font-size: 1.375rem;
    font-weight: 600;
    letter-spacing: 0.83px;
    text-align: center;
    color: var(--primary-text-color);
    margin-bottom: 2.5rem;
  }

  &__product {
    position: relative;
    margin-bottom: 2.5rem;

    &__gradient_overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(360deg, var(--bg-color) 0%, rgba(0, 0, 0, 0) 35.56%);
    }

    &__image {
      position: relative;
      padding-top: 56.25%;
      margin-bottom: 10px;
    }

    &__caption {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      bottom: 0;
      padding: 2.5rem;
    }

    &__title {
      font-size: 1.5rem;
      font-weight: 900;
      padding-bottom: 0.5rem;
      color: var(--primary-text-color);
    }
  }

  &__buttons_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    margin: 1rem;
  }
}

.MediaAdditionalInfo {
  font-size: 1rem;
  font-weight: 900;
  color: var(--primary-text-color);
}
