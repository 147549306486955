@import "../MediaDetailsScreen/DetailsScreenSharedStyles.scss";

.MediaDetails {
  width: 100%;
  display: flex;
  flex-direction: column;

  .AppHeader {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &__section {
    flex: 1 0 80vh;
  }

  &__background {
    @extend %responsive-aspect-ratio;
    position: absolute;
    pointer-events: none;
    user-select: none;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #000000;
    overflow: hidden;

    @extend %header-height;

    &__teaserContainer {
      position: absolute;
      width: 100%;

      position: absolute;
      left: 50%; /* % of surrounding element */
      top: 50%;
      transform: translate(-33%, -50%);

      & .VideoPlayer  {
        height: 100%;
        & > .brightcove-react-player-loader {
          height: 100%;
          & > .video-js {
            margin: auto 0;
            height: 100%;
            background: none !important;
          }
        }
      }
    }

    &__teaserGradientVertical {
      background: linear-gradient(90deg, #000000 71%, rgba(0, 0, 0, 0) 100%);
      width: 47%;
      height: 100%;
      position: absolute;
      top: 0;
    }

    &__imageOverlayGradientVertical {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(25, 25, 25, 0.697899) 33%,
        hsla(0,0%,100%,0) 55%
      );
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
  }

  .MediaButton {
    white-space: nowrap;
  }
}
