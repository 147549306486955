@import "src/sass/utils";
@import "src/sass/variables";

.ListComponentItemChannel {
  position: relative;
  transition: all 0.2s;
  font-size: 1rem;
  aspect-ratio: 3.21/1;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 35% 65%;
  background-color: $item-channel-background;
  cursor: pointer;
  overflow: hidden;

  &-container {
    position: relative;
    margin: 0 11.5px;
    aspect-ratio: 3.21/1;
    border-radius: 10px;
    background: var(--cell-background-color);

    &--skeleton {
      aspect-ratio: 3.21/1;
      position: absolute;
      width: 100%;
    }
  }

  &-image-container {
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1/1;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    background-color: var(--cell-background-color);
  }

  &-image {
    width: 100%;
    height: -webkit-fill-available;

    img {
      border-radius: 10px 0 0 10px;
      object-fit: cover;
    }
  }

  &-information {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 22px 0 12px;

    &--title {
      @include text-style('half', $bold: 'bolder');
      display: -webkit-box;
      word-wrap: break-word;
      text-overflow: ellipsis;
      max-height: 2.3em;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      opacity: 0.87;
      letter-spacing: 0.9px;
    }

    &--duration {
      @include text-style('half', $bold: 'lighter');
      @include half-size('margin-top');
    }
  }
}
