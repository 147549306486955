@import "../../DetailsScreenSharedStyles.scss";

.SeasonPicker {
  &__listMode {
    @extend %responsive-padding;

    display: flex;
    align-items: center;
    @include half-size("margin-bottom");

    .vertical-divider {
      height: 34px;
      width: 0px;
      border: 1px solid #3f8dea;
      margin: 0px 31px;

      @include desktopDown {
        height: 25px;
      }
    }

    &--text {
      @include text-style();
    }

    &-button {
      text-decoration: underline;
      text-underline-offset: 3px;
      cursor: pointer;
      @include text-style();
    }
  }

  &__seasons {
    $horiz-scrollbar-padding: 30px;
    position: relative;
    @extend %responsive-padding;
    display: flex;
    padding-bottom: $horiz-scrollbar-padding;
    overflow-x: auto;

    @include desktopDown {
      align-items: center;
      gap: 2rem;
    }
    &--text {
      @include font(20px, 18px, 300);
      align-self: center;

      @include text-style();
    }

    &::after {
      position: absolute;
      bottom: $horiz-scrollbar-padding - 3px;
      content: "";
      width: 80%;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(238, 238, 238, 0.3) 50%,
        rgba(238, 238, 238, 0) 100%
      );
    }
  }

  &__season-section {
    margin-top: 86px;
  }

  &__buttons-container {
    display: flex;

    gap: 20px;

    padding-left: 50px;

    .seasonsButton {
      p {
        @include line-clamp-ellipsis(4);
        text-align: center;
        color: var(--primary-text-color);
        @include text-style('half', 'bolder');
      }

      background-color: transparent;
      border: none;
      position: relative;
      width: 100%;
      max-width: max-content;
      height: 100%;

      @include desktopDown {
        font-size: 20px;
      }

      &::after {
        position: absolute;
        left: 0;
        bottom: -4px;
        content: "";
        width: 100%;
        height: 3px;
        background-color: #3f8dea;
        opacity: 0;
      }

      &-selected::after {
        opacity: 1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__season-dropdown {
    width: 100%;
    max-width: max-content;
  }
}

.GridComponent.EpisodesGrid {
  @extend %responsive-padding;
  margin-top: 3rem;
}
