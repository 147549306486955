@import "src/sass/variables";

.creators-form {
  display: flex;
  flex: 1;
  flex-direction: column;

  .Error {
    font-size: 12px;
    color: $error-color;
    list-style: none;
    margin-top: -10px;
    padding: 0;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
    li {
      margin: 0;
    }
  }
}

.creators-section {
  flex: 1;
  flex-basis: 0;
}
