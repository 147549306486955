@import "src/sass/utils";

.TextWidget {
  &__WithImage {
    min-height: 508px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover !important;
    background-position: center !important;
    @include phone {
      min-height: 314px;
    }
    @include tablet {
      min-height: 339px;
    }

    &-gradient {
      width: 100%;
      height: 100%;
      position: relative;
      left: 0;
      top: 0;
      background: linear-gradient(
        0,
        rgba(19, 26, 42, 0.8) 31.57%,
        rgba(19, 26, 42, 0) 79.42%
      );
    }

    &-header {
      font-weight: 400;
      font-size: 42px;
      line-height: 47px;
      text-align: center;
      margin-bottom: 35px;
      position: relative;
      z-index: 1;
      @include phone {
        font-size: 24px;
        margin-top: 185px;
      }
      @include tablet {
        font-size: 40px;
        margin-top: 153px;
      }
      @include desktop {
        font-size: 42px;
        margin-top: 153px;
      }
    }

    &-content {
      margin: 65px 130px 65px 205px;
      text-align: center;
      font-size: 20px;
      line-height: 26px;
      z-index: 1;
      word-wrap: break-word;
      @include phone {
        font-size: 16px;
        margin: 0px 16px 37px 16px;
      }
      @include tablet {
        font-size: 24px;
        margin: 0px 50px 37px 50px;
      }
      @include desktop {
        font-size: 25px;
        margin: 0px 50px 37px 50px;
      }
    }
  }

  &__WithoutImage {
    padding: 75px 300px;
    @include phone {
      padding: 0px 16px;
    }
    @include tablet {
      padding: 0px 50px;
    }
    @include desktop {
      padding: 0px 60px;
    }

    &-header {
      font-weight: 400;
      font-size: 42px;
      line-height: 47px;
      margin-bottom: 40px;
      @include phone {
        font-size: 24px;
        padding: 16px 0px;
        margin-bottom: 24px;
      }
      @include tablet {
        font-size: 40px;
        padding: 32px 0px;
      }
      @include desktop {
        font-size: 42px;
        padding: 34px 0px;
      }
    }

    h1 {
      @include phone {
        margin: 0px 0px;
        padding-bottom: 0px;
      }
      @include tablet {
        margin: 0px 0px;
        padding-bottom: 0px;
      }
      @include desktop {
        margin: 0px 0px;
        padding-bottom: 0px;
      }
    }

    &-content {
      font-size: 20px;
      line-height: 26px;
      margin: 0;
      @include phone {
        font-size: 16px;
        padding: 24px 0px;
      }
      @include tablet {
        font-size: 24px;
        padding-top: 32px;
        padding-bottom: 58px;
      }
      @include desktop {
        font-size: 24px;
        padding-top: 32px;
        padding-bottom: 58px;
      }
    }
  }
}

.isRenderedFirst {
  margin-top: -164px;
}
