@import "src/sass/utils";
@import "src/sass/variables";

.RegisterForm {
  margin: auto;
  width: 67%;

  @include phone {
    width: 90%;
  }

  @include tablet {
    width: 80%;
  }

  &__title {
    margin: 0;
    @include text-style();
    text-transform:uppercase;
    color: var(--primary-text-color);
    line-height: 47px;
  }

  &__form {
    width: 100%;
  }

  &__subTitle {
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    padding: 0;
    margin: 0;
    color: var(--primary-text-color);
    letter-spacing: 0.2px;
    margin-top: 33px;
  }

  &__container {
    margin-top: 54px;

    @include phone {
      margin-top: 16px;
    }
  }

  &__input, input.Input__input {
    margin-bottom: 26px;
    letter-spacing: 0.2px;
    @include text-style();
  }

  &__back {
    margin-top: 46px;
    font-weight: 300;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: var(--primary-color);
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    margin-bottom: 30rem;
  }

  &__consents {
    margin: 2rem 2px 1rem;
  }

  &__button {
    border: none;
    outline: none;
    width: 100%;
    height: 62px;
    cursor: pointer;
    background: var(--primary-color);
    border-radius: 70px;
    letter-spacing: 0.2px;
    color: var(--primary-text-color);
    margin-top: 20px;
    text-transform: uppercase;

    @include text-style();

    &:disabled {
      background-color: $disable-bg;
      color: $disable-text;
      cursor: not-allowed;
    }

    &--transparent {
      background: transparent;
      border: 1px solid var(--primary-text-color);
      margin: 36px 0 300px;
    }

    @include phone {
      height: 50px;
    }
  }

  .text-link, .link, .FormInput > input, .Label > label, .InputSelect-selection-search > .InputSelect-selection-search-input, .InputSelect-selector >.InputSelect-selection-placeholder, .InputSelect-item-option-content {
    @include text-style();
  }

  .MediaButton__text, .TransparentButton {
    @include text-style('half', 'bolder');
  }
}

.RegisterConsents {
  &__Option {
    padding: 1em 0;
    label {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    &__Description {
      padding-left: 1em;
    }
  }
}

.RegisterForm__optionLogo {
  width: 58px;
  height: 58px;
  margin: 0 20px 0 10px;
  overflow: hidden;
  object-fit: contain;

  img {
    object-fit: contain;
    border-radius: 100%;
  }
}
