@import "src/sass/utils";
@import "src/sass/variables";

.Container {
  width: calc(33.33% + 55px);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1;

  @include tabletDown {
    width: 100%;
  }

  &__content {
    padding-left: 55px;
    display: block;
    margin: auto 0 35px;
    justify-content: center;
  }

  &__category {
    @include text-style();
    margin: 0;
    line-height: 1;
    color: var(--link-text-color-d-30);
    text-shadow: $text-shadow;
  }

  &__parent {
    @include text-style();
    margin: 23px 0 0 0;
    color: #f5f5f5;
    text-shadow: $text-shadow;
    text-transform: uppercase;
  }

  &__label {
    @include text-style();
    margin: 23px 0 0 0;
    color: #f5f5f5;
    text-shadow: $text-shadow;
  }

  &__title {
    @include text-style("main", "bolder");
    margin: 20px 0 0 0;
    color: #f5f5f5;
    text-shadow: $text-shadow;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include tabletDown {
      margin: 16px 0 0 0;
    }
  }

  &__description {
    @include text-style();
    margin: 20px 0 0 0;
    color: #f5f5f5;
    text-shadow: $text-shadow;
  }

  &__buttons {
    margin-top: 40px;
    display: flex;

    &:first-of-type div {
      margin-right: 35px;
    }

    &_redirect {
      cursor: pointer;
      height: 75px;
      width: 75px;
      border-radius: 50%;
      svg {
        height: 100%;
        width: 100%;
      }
    }

    @include tablet {
      &:first-of-type div {
        margin-right: 25px;
      }

      &_redirect {
        height: 61px;
        width: 61px;
      }
    }
    @include phone {
      &_redirect {
        height: 53px;
        width: 53px;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__startDateTime {
    position: relative;
    margin-left: 0;
    width: auto;
    @include display-flex();
    @include display-algin(center);
    padding: 5px 15px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid white;

    * + &{
      margin-left: 20px;
    }

    &__text {
      @include ellipsis();
      @include text-style('half', $bold: 'lighter');
      font-style: normal;
      letter-spacing: 0.2px;
      text-shadow: $text-shadow;
      line-height: 1em;
    }
  }


  @include phone {
    &__content {
      margin: auto 0 24px 0;
      padding-left: var(--app-padding-left);
    }
  }
}
