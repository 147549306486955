@import "src/sass/variables";
@import "src/sass/utils";

.ForgotPasswordForm {
  width: 100%;
}

.ResetForgotPasswordForm {
  width: 100%;
}

.ForgotPasswordBackContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
}
