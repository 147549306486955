@import "src/sass/styles";

.ImageOverlay {
  &-container {
    background-color: transparent;
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    left: 0%;
    justify-content: center;
    align-items: center;
    z-index: -1;
  }

  &-image {
    @extend %imageWithRatio;
  }

  &-overlay {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
}
