@import "src/sass/utils";

.Overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background: $overlay-menu;

  &_menu {
    margin-left: 50px;
    margin-top: 86px;


    .Menu-vertical .Menu-item {
      width: max-content;
      padding: 0;
      margin: 0;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
    }

    .Menu:not(.Menu-horizontal) .Menu-item-selected {
      background-color: transparent;

    }

    .Menu {
      &:not(&-horizontal) &-item-selected {
        background-color: transparent;
      }
      &-item:active,
      &-submenu-title:active {
        background: transparent;
      }
    }
  }

  &_close {
    position: absolute;
    right: 54px;
    top: 68px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &_informations {
    position: absolute;
    bottom: 46px;
    left: 51px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
  }

  &_separator {
    width: 45%;
    height: 1px;
    background-color: #6F6F6F;
    margin-top: 19px;
    margin-bottom: 24px;
  }
}
