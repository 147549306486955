@import "src/sass/utils";

.PageHeader {
  position: relative;
  z-index: 95;
  display: flex;
  @include half-size('margin-bottom');
  align-items: center;
  gap: 10px;

  &__back {
    @include prevent-tab-highlights;
    cursor: pointer;
    width: 20px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    @include text-style();
    color: rgb(255,255,255);
    text-transform: uppercase;
    line-height: 1em;
  }
}
