@import "src/sass/utils";
@import "src/sass/variables";

.MediaAdditionalInfo {
  & > div {
    @include text-style();
    text-shadow: $text-shadow;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
  }

  &__box {
    font-size: 0.7rem;
    display: inline-block;
    border: 0.5px solid var(--primary-text-color);
    padding: 2px 10px;
    border-radius: 10%;
    margin-left: 10px;
  }
}
