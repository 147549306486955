.MainScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  section {
    flex: 1;
  }

  &-content-list {
    padding-bottom: 80px;
  }

  .GridComponent {
    padding-left: var(--app-padding-left);
    padding-right: var(--app-padding-right);
  }
}
