@import "../../sass/variables";
@import "../../sass/utils";

.ConsentModal {
  .Dialog {
    text-align: center;
  }
  .Dialog-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include tablet {
      span {
        margin: 15px 0 15px 0;
      }
    }
  }
  .ErrorMessage {
    padding: 16px 0;
  }
  .MediaButton {
    margin: 25px 5px;
    min-width: 200px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      padding-top: 6px;
    }
    @include tablet {
      margin: 10px;
    }
    @include phone {
      margin: 5px;
    }
  }
  .Dialog-close {
    display: none;
  }
}
