@import "src/sass/variables";
@import "src/sass/utils";

.AuthForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 170px;

  h1 {
    @include text-style();
    @include half-size('margin-bottom');
    margin-top: 20px;
    text-transform: uppercase;
  }

  p {
    @include text-style();
    margin-top: 12px;
  }

  .ForgotPasswordBack {
    @include text-style();
  }

  .ForgotPasswordSuccessParagraph {
    margin-top: 31px;
  }

  form,
  .form {
    @include text-style();
    width: 67%;
    margin-bottom: 50px;

    @include phone {
      width: 90%;
    }

    @include desktop {
      width: 58%;
    }
  }

  .ButtonLine {
    width: 100%;
    text-align: center;
  }

  .text-link, .FormInput > input, .Label > label {
    @include text-style();
  }

  .MediaButton__text, .TransparentButton {
    @include text-style('half', 'bolder');
  }
}
