@import "../MediaDetailsScreen/DetailsScreenSharedStyles.scss";

.AlbumDetails {
  &__info-container {
    @extend %info-container;
  }

  &__info {
    @extend %info;

    &--flex-end {
      justify-content: flex-end;
    }
  }

  &__main-info {
    @extend %main-info;
    justify-content: flex-end;
    gap: 0.5rem;

    width: 100%;
    margin-bottom: 0;
    padding-bottom: 1rem;

    @include phone() {
      gap: 1rem;
      padding-bottom: 5%;
    }
  }

  &__genres {
    @extend %genres;
    span {
      @include largeDown() {
        @include line-clamp-ellipsis(1);
      }
    }
  }

  &__title {
    @extend %title;
  }

  &__actions {
    @extend %actions;

    .MediaButton {
      @include tablet {
        margin-top: 0;
      }
    }
  }

  &__details {
    &-container {
      @extend %details-container;
    }

    &__description,
    &__people {
      @extend %details;

      &-header {
        @extend %details-header;
      }

      &-text {
        @extend %details-text;
        margin-bottom: 3rem;
      }
    }
  }
}
