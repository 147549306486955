@import "src/sass/utils";
@import "src/sass/epg";

.epg-desktop-scroll-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  background: $background-secondary-color;

  &.fixed-epg-elements {
    margin-top: 84px;

    @include largeDown() {
      margin-top: 44px;
    }
  }

  &::-webkit-scrollbar-track {
    background: $background-secondary-color;
  }

  &::-webkit-scrollbar-corner {
    background: $background-secondary-color;
  }

  &-content {
    position: relative;
    background: $background-color;
    left: $epg-desktop-sidebar-width;
    overflow: hidden;
    z-index: 1;

    @include largeDown() {
      left: $epg-desktop-sidebar-width-tablet;
    }
  }
}
