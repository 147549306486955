@import "src/sass/utils";

.HeaderLogo {
  display: flex;

  &__image {
    max-width: 170px;
    filter: $icon-shadow;

    @include phone {
      height: 38px;
    }
  }
}
