@import "src/sass/utils";

.InfoDetailsScreen {
  &__Container {
    position: relative;
    z-index: 1003;
    width: 100%;
  }

  &__Content {
    width: 100%;
    border: 0px;
    padding-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  &__IFrame {
    width: 96%;
    padding-top: 25px;
    padding-left: 35px;
    border: 0px;
    scroll-behavior: smooth;

    h1 {
      @include text-style();
    }
  }
}
