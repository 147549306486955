.catchup-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .Select-selection-item,
  .Select-selection-placeholder {
    text-transform: none;
  }

  .payments-switch {
    label {
      margin-bottom: 8px;
      font-size: 16px;
      letter-spacing: 0.6px;
      opacity: 0.87;
    }
  }

  .react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }

  label {
    display: block;
  }

  .broadcast-date-time-section {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    margin-top: 50px;
  }
}

.catchup-section,
.catchup-section > form {
  flex: 1;
  display: flex;
  flex-direction: column;
}
