@import "src/sass/styles";

.ImageWithRatio {
  position: static;
  @extend %header-height;

  &__image {
    @extend %imageWithRatio;
  }

  &__Content {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__Spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.placeholder .ImageWithRatio__image {
  background-size: unset;
  background-position: center;
}