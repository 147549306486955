@import "src/sass/utils";

.TableRow:first-child {
  cursor: default;
}

.TableRow:nth-child(2n) {
  background-color: rgba(196, 196, 196, 0.2);
}

.TableRow:nth-child(2n+3) {
  background-color: rgba(196, 196, 196, 0.1);
}

.TableRow {
  margin-top: 8px;
  border-radius: 8px;
  display: flex;
  min-height: 64px;
  flex-direction: column;

  &__BasicInfoContainer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }

  &-expanded {
    border: 1px solid #4A90E2;
  }

  &__Column:first-child {
    flex: 1;
    justify-content: center;
  }

  &__Column {
    flex: 2 1 0;
    display: flex;
    overflow: hidden;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: var(--primary-text-color);
  }

  &__image-container {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin: 8px 8px 8px 0;
    position: relative;
    padding-top: 50%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__dropdown-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.25s;
    transition-property: transform;

    &-expanded {
      transform: rotate(-180deg);
    }
  }
}

.Title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  padding: 4px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
