.ApplicationMenuItem {
  opacity: 0.87;
  font-size: 1.375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.1px;
  text-align: center;
  list-style-type: none;
  cursor: pointer;
  position: relative;
  color: var(--header-link-color);

  &:hover {
    color: var(--header-link-hover-color);
  }
}
