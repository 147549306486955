@import "src/sass/variables";

.creators {
  .asset-creator-list {
    list-style: none;
    padding: 0;

    h3 {
      font-size: 1rem;
    }

    li {
      display: flex;
      gap: 30px;
      margin-bottom: 16px;
      align-items: center;
      padding: 4px;
      align-items: flex-end;

      label {
        display: block;
      }
    }

    .sharing-input {
      border: solid 1px rgba(255, 255, 255, 0.22);
      background-color: var(--bg-color);
      font-size: 1rem;
      min-width: 200px;
      border-radius: 30px;
      margin-top: 10px;
      padding: 8px 16px;
      height: 1.4rem;
      color: white;
      outline: 0;
      cursor: pointer;
    }

    .asset-list-add {
      display: flex;
      align-items: center;
      background: none;
      border: 0;
      color: var(--primary-text-color);

      svg {
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        font-size: 1.5rem;
        border-radius: 45px;
        margin-right: 8px;
      }
    }

    .Select-selector {
      .Select-selection-item,
      .Select-selection-placeholder {
        font-size: 1rem;
        text-transform: none;
        left: 16px;
      }
    }
    .remove-button {
      margin-top: auto;
      font-size: 2rem;
      background: none;
      border: 0;
      color: white;
    }
  }
}
