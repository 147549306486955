@import "src/sass/utils.scss";

.AuthScreen {
  width: 100%;
  height: 100%;
  position: relative;

  .AppHeader {
    position: absolute;
    width: 100vw;
    box-sizing: border-box;
    z-index: 99;
  }

  &__container {
    display: flex;
    margin-bottom: 0px;

    @media screen and (min-width: $tablet-min-width) {
      margin-bottom: 100px;
    }

    @media screen and (min-width: 575px) {
      margin-bottom: 0;
    }

    @media screen and (min-width: $large-min-width) {
      margin-bottom: 100px;
    }
    
    @media screen and (min-width: $large-max-width) {
      margin-bottom: -100px;
    }

    @media screen and (min-width: 1445px) {
      margin-bottom: -180px;
    }

  }

  &__content {
    flex: 1;
    padding-top: 180px;
    max-width: 46%;
    background-color: var(--bg-color);

    @include desktop {
      max-width: 100%;
    }

    @include tablet {
      max-width: 100%;
    }

    @include phone {
      max-width: 100%;
      padding-top: 114px;
    }
  }

  &__image {
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      min-height: 95vh;
      object-fit: cover;
      object-position: center center;
      opacity: 0.7;
      margin-bottom: -20%;
    }

    @include desktop {
      display: none;
    }

    @include tablet {
      display: none;
    }

    @include phone {
      display: none;
    }
  }
}

.Version {
  position: fixed;
  bottom: 2%;
  right: 2%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 4px;

  span {
    font-size: 10px;
    margin-left: 4px;
    color: #727272;
  }
}
