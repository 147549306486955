@import "src/sass/utils";
@import "src/sass/variables";

.ListComponentItemRound {
  position: relative;
  transition: all 0.2s;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background-color: var(--cell-background-color);
  cursor: pointer;

  &-container {
    position: relative;
    margin: 0 8.5px;
    aspect-ratio: 1/1;
    border-radius: 100%;
    &--skeleton {
      background: var(--cell-background-color);
    }
  }
  &-image-container {
    aspect-ratio: 1/1;
    border-radius: 100%;
    height: 100%;
  }
  &-image {
    width: 100%;
    height: 100%;
    img {
      border-radius: 100%;
      object-fit: contain;
    }
  }
  &-skeleton {
    border-radius: 100%;
    position: absolute;
    width: 100%;
    bottom: 26px;
    left: 20px;
  }
}
