@import "src/sass/utils";
@import "src/sass/epg";

.epg-desktop-day-switcher {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  label {
    display: none;
  }

  @include largeDown() {
    justify-content: flex-start;
    padding-left: 25px;
    padding-right: 25px;
  }

  &-dropdown {
    display: none;
    flex-direction: row;
    gap: 40px;
    justify-content: flex-start;
    align-items: center;
    width: 400px;

    @include largeDown() {
      display: flex;
    }

    &-current-date {
      flex-shrink: 0;
      font-weight: 400;
      font-size: 24px;
    }

    & input {
      caret-color: transparent;
      cursor: pointer;
    }

    & .InputSelect-selection-item, & .InputSelect-selection-placeholder {
      padding: 20px !important;
    }
  }

  &-button {
    border: 4px solid $text-color;
    color: $text-color;
    border-radius: 56px;
    background: transparent;
    height: 72px;
    box-shadow: none;
    font-weight: 400;
    font-size: 24px;
    padding: 0 10px;


    @include largeDown() {
      display: none;
    }


    &:hover {
      border: 4px solid $active-color;
      background-color: transparent !important;
      color: $text-hover-color;
    }

    &-active {
      background: $active-color;
      border: 4px solid $active-color;
      color: $text-hover-color;

      &:hover {
        background-color: $active-color !important;
      }
    }
  }
}
