@import "src/sass/variables";
@import "src/sass/utils";

.ListComponentPodcastItem {
  box-sizing: border-box;
  position: relative;
  transition: transform 0.2s;
  border-radius: 5px;
  background-color: var(--cell-background-color);
  cursor: pointer;
  overflow: hidden;
  height: fit-content;

  @include half-size('margin-inline');
  @include half-size('padding');

  &:hover, &:focus {
    transform: scale(1.065);
    z-index: 2;
    outline: 2px solid #3f8deadd;
  }

  &-container {
    border-radius: 5px;
  }

  &-info {
    position: absolute;
    box-sizing: border-box;
    padding: 35px 100px 35px 35px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2px;
    height: 100%;
    width: 100%;

    &-date {
      @include text-style();
      @include half-size('margin-bottom');
    }

    &-title {
      @include line-clamp-ellipsis(1);
      @include ellipsis();
      @include text-style('half', 'bolder');
      @include half-size('margin-bottom');
    }

    &-description {
      @include line-clamp-ellipsis(4);
      @include ellipsis();
      @include text-style();
      @include half-size('margin-bottom');
    }

    &-buttonSection {
      @include display-flex();

      &-duration {
        @include display-flex();
        align-items: center;
        @include text-style();
        @include half-size('margin-left');
      }

      &-icon {
        position: relative;
        box-sizing: border-box;
        z-index: 1;
        width: 65px;
        height: 65px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
